'use client'
import dynamic from 'next/dynamic'
import SuitcaseContained from '@assets/icons/suitcase-contained.svg'
import ChevronDown from '@assets/icons/chevron-down.svg'
import USPIcon from '@assets/icons/usp-checkmark.svg'
import HeadingIllustration1 from '@assets/icons/heading-illustration-1.svg'
import HeadingIllustration2 from '@assets/icons/heading-illustration-2.svg'
import HeadingIllustration3 from '@assets/icons/heading-illustration-3.svg'
// the ICON_ELEMENTS export can not be in the same file as the icons constants
// We are using next/dynamic to load these icons dynamically, removing them from the initial bundle
// If we are using the icon constants in the same client file, it will throw server components errors in server components that require icons

// Some icons are used above the fold.
// We do not want to lazy-load these icons, as lazy loading them would cause layout shifts (CLS)
const ABOVE_THE_FOLD_ICONS = {
  SUITCASE_CONTAINED: SuitcaseContained,
  CHEVRON_DOWN: ChevronDown,
  USP_ICON: USPIcon,
  HEADING_ILLUSTRATION_1: HeadingIllustration1,
  HEADING_ILLUSTRATION_2: HeadingIllustration2,
  HEADING_ILLUSTRATION_3: HeadingIllustration3,
}

export const ICON_ELEMENTS = {
  ...ABOVE_THE_FOLD_ICONS,
  LOGO_ERLEBE: dynamic(() => import('@assets/icons/logo-erlebe.svg')),
  GLOBE_ICON: dynamic(() => import('@assets/icons/globe.svg')),
  CHEVRON_UP: dynamic(() => import('@assets/icons/chevron-up.svg')),
  CHEVRON_RIGHT: dynamic(() => import('@assets/icons/chevron-right.svg'), {
    ssr: false,
  }),
  CHEVRON_LEFT: dynamic(() => import('@assets/icons/chevron-left.svg')),
  SUITCASE_OUTLINED: dynamic(() =>
    import('@assets/icons/suitcase-outlined.svg')
  ),
  CLOSE_ICON: dynamic(() => import('@assets/icons/close.svg')),
  SUCCESS: dynamic(() => import('@assets/icons/success.svg')),
  SLIDE_LEFT: dynamic(() => import('@assets/icons/slide_left.svg')),
  SLIDE_RIGHT: dynamic(() => import('@assets/icons/slide_right.svg')),
  ARROW_LEFT: dynamic(() => import('@assets/icons/arrow-left.svg')),
  ARROW_RIGHT: dynamic(() => import('@assets/icons/arrow-right.svg')),
  LOCATION_MARKER: dynamic(() => import('@assets/icons/location_marker.svg')),
  CALENDAR: dynamic(() => import('@assets/icons/calendar.svg')),
  CHECKMARK: dynamic(() => import('@assets/icons/checkmark.svg')),
  NAV_UNDERLINE: dynamic(() => import('@assets/icons/nav_underline.svg')),
  MOBILE: dynamic(() => import('@assets/icons/mobile.svg')),
  LINK_ARROW: dynamic(() => import('@assets/icons/link-arrow.svg')),
  HAMBURGER: dynamic(() => import('@assets/icons/hamburger.svg')),
  PUZZLE: dynamic(() => import('@assets/icons/puzzle.svg')),
  PUZZLE_FAMILY: dynamic(() => import('@assets/icons/puzzle-family.svg')),
  BLOCK_LINK: dynamic(() => import('@assets/icons/block-link.svg')),
  PUZZLE_NUMBER_1: dynamic(() => import('@assets/icons/puzzle-number-1.svg')),
  PUZZLE_NUMBER_2: dynamic(() => import('@assets/icons/puzzle-number-2.svg')),
  PUZZLE_NUMBER_3: dynamic(() => import('@assets/icons/puzzle-number-3.svg')),
  PUZZLE_INDEX: dynamic(() => import('@assets/icons/puzzle-index.svg')),
  CLOCK: dynamic(() => import('@assets/icons/clock.svg')),
  MAP_ROUTE: dynamic(() => import('@assets/icons/map-route.svg')),
  CURRENCY_EUR: dynamic(() => import('@assets/icons/currency.svg')),
  CURRENCY_GBP: dynamic(() => import('@assets/icons/currency_pound.svg')),
  ZOOM: dynamic(() => import('@assets/icons/zoom.svg')),
  PLUS: dynamic(() => import('@assets/icons/plus_icon.svg')),
  PHONE: dynamic(() => import('@assets/icons/phone.svg')),
  MOBILE_SLIDE_NEXT: dynamic(() =>
    import('@assets/icons/mobile-slide-next.svg')
  ),
  MOBILE_SLIDE_PREV: dynamic(() =>
    import('@assets/icons/mobile-slide-prev.svg')
  ),
  MOBILE_DRAWING: dynamic(() => import('@assets/icons/mobile-drawing.svg')),
  FACEBOOK: dynamic(() => import('@assets/icons/facebook.svg')),
  INSTAGRAM: dynamic(() => import('@assets/icons/instagram.svg')),
  PINTEREST: dynamic(() => import('@assets/icons/pinterest.svg')),
  TWITTER: dynamic(() => import('@assets/icons/twitter.svg')),
  X: dynamic(() => import('@assets/icons/twitter.svg')),
  YOUTUBE: dynamic(() => import('@assets/icons/youtube.svg')),
  EDIT: dynamic(() => import('@assets/icons/edit-icon.svg')),
  EMAIL: dynamic(() => import('@assets/icons/email.svg')),
  PAPER_PLANE: dynamic(() => import('@assets/icons/paper-plane.svg')),
  ERLEBE_HEART: dynamic(() => import('@assets/icons/erlebe-hertz.svg')),
}
