'use client'
import { useRef } from 'react'
import { RaBadge } from '@core'
import { useIntersectionObserver } from '@/utilities/hooks/useIntersectionObserver.hooks'
import { ICON_ELEMENTS } from '../../../constants/icon-elements.constants'

interface RaIconProps {
  icon: string
  size?: 's' | 'm' | 'l'
  color?:
    | 'light'
    | 'dark'
    | 'primary'
    | 'success'
    | 'error'
    | 'translucent-dark-1'
    | 'translucent-dark-2'
    | 'erlebe'
  badge?: number | '+' | '-'
  badgeTextColor?: string
  badgeColor?: string
  customClassNames?: any
  minHeight?: number
  minWidth?: number
  useRootEl?: boolean
}

export const SVG = ({
  icon,
  color = 'primary',
  badge,
  badgeTextColor = '#FFF',
  badgeColor = 'var(--color-primary)',
  customClassNames,
}: RaIconProps) => {
  const Icon = ICON_ELEMENTS[icon]
  if (!Icon) return null

  if (badge)
    return (
      <>
        <Icon
          className={customClassNames ? customClassNames : {}}
          color={`var(--color-${color})`}
        />
        <RaBadge
          badge={badge}
          color={badgeTextColor}
          badgeColor={badgeColor}
        />
      </>
    )

  return (
    <Icon
      className={customClassNames ? customClassNames : {}}
      color={`var(--color-${color})`}
    />
  )
}

export const RaIcon = (props: RaIconProps) => {
  const ref = useRef(null)
  const { hasIntersected } = useIntersectionObserver(ref)

  const { minWidth, minHeight, useRootEl } = props

  // If we do not use the intersection observer, all SVG icons will be loaded on the initial page load.
  // This significantly slows down the pagespeed (yes, really) so we only load the icons when they're in viewport.
  // But the wrapper <div> causes some issues for some absolutely positioned icons, for example on form inputs.
  // In that case we render the icon's root element without the wrapper
  if (useRootEl && hasIntersected) {
    return <SVG {...props} />
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        minWidth: typeof minWidth !== 'undefined' ? minWidth : 24,
        minHeight: typeof minHeight !== 'undefined' ? minHeight : 24,
      }}
      ref={ref}>
      {hasIntersected && <SVG {...props} />}
    </div>
  )
}
